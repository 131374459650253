import {
  Identity,
  Layout,
  UserBank,
} from '@octanner/prism-core/Header/interfaces';
import { useTranslation } from 'react-i18next';
import { DateTime, Settings } from 'luxon';
import { MultipleBankAndAccessCodes } from './types';

const getBankUrl = (bank: UserBank, currentIdentity: Identity | undefined) => {
  const lang = currentIdentity?.language || Settings.defaultLocale;
  if (!currentIdentity) return '';
  const queryParams = new URLSearchParams({
    countryCode:
      currentIdentity.profile?.catalogCoreCountry?.iso2Code ||
      currentIdentity.profile?.homeCoreCountry?.iso2Code ||
      '',
    programId: bank?.programId?.toString() || '',
    refererUrl: '',
    locale: (lang || navigator.languages?.[0] || navigator.language).replace(
      '-',
      '_',
    ),
  });
  return `/catalog?${queryParams}`;
};

export const getMultipleBanks = (
  layout: Layout | undefined,
  currentIdentity: Identity | undefined,
  hasMultipleBank: boolean,
) => {
  const { t } = useTranslation();
  const multipleBanks = sort(
    layout?.bankBalance?.map((bank) => {
      const accessCodes = bank?.accessCodeDetails?.map((access) => {
        const dateTime = DateTime.fromFormat(
          access.expiresAt,
          'yyyy-MM-dd hh:mm:ss.S',
        );
        return {
          category: t('prism-header:access-codes', 'Access Codes'),
          primary: access.accessCode,
          secondary: t(
            'prism-header:expiring-access-codes',
            'Expires {accessCodeDate}',
            {
              accessCodeDate: dateTime.toLocaleString(DateTime.DATE_SHORT),
            },
          ),
          url: getBankUrl(bank, currentIdentity),
        };
      });
      return {
        category: t('prism-header:bank-accounts', 'Bank Accounts'),
        primary: bank.bankName,
        secondary: bank?.points?.toString(),
        url: getBankUrl(bank, currentIdentity),
        accessCodes,
        defaultBank: bank.defaultBank,
      };
    }) || [],
    'defaultBank',
  );
  const banksWithAccessCodes = multipleBanks?.filter(
    (o) => o.accessCodes && o.accessCodes.length > 0,
  );

  const accessCodes = banksWithAccessCodes?.flatMap((bank) => {
    return bank.accessCodes || [];
  }) as MultipleBankAndAccessCodes[];

  const vettedMultipleBanks = multipleBanks?.map((bank) => {
    return {
      category: bank.category,
      primary: bank.primary,
      secondary: bank.secondary,
      url: bank.url,
    };
  }) as MultipleBankAndAccessCodes[];

  console.log(vettedMultipleBanks, accessCodes);

  return hasMultipleBank ? { vettedMultipleBanks, accessCodes } : {};
};

const sort = <Type,>(arr: Type[], key: keyof Type) =>
  arr?.sort((a, b) => {
    if (a[key]) return -1;
    if (b[key]) return 1;
    return 0;
  });
// sort multiple banks by default bank

export const sortAccess = (accessCodes: MultipleBankAndAccessCodes[]) =>
  accessCodes?.sort((a, b) => {
    const dateA = a.secondary?.split(' ')[1];
    const dateB = b.secondary?.split(' ')[1];
    return (
      new Date(dateA).getTime() - new Date(dateB).getTime() ||
      a.secondary?.localeCompare(b.secondary)
    );
  });
